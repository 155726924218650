import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appThemed]'
})
export class ThemedDirective implements OnInit {
  private _styles: any = {};
  private theme: Record<string, string> = {};

  @Input('appThemed')
  set appThemed(styles: Record<string, string>) {
    this._styles = styles || {};
    this.setStyles();
  }

  constructor(private el: ElementRef) {
    // Synchronously fetch and parse theme from localStorage
    this.loadThemeFromLocalStorage();
  }

  ngOnInit(): void {
    this.setStyles();
  }

  /**
   * Load theme configuration from localStorage
   * This is a synchronous operation to ensure the theme is applied immediately
   */
  private loadThemeFromLocalStorage(): void {
    const config = localStorage.getItem('organisationConfig');
    if (config) {
      try {
        const { theme } = JSON.parse(config);
        this.theme = {
          primary: theme.primaryColour || '#000000',
          secondary: theme.secondaryColour || '#FFFFFF',
          accent: theme.accentColour || '#FF0000',
          previouslyAssessedTrainingItemColour: theme.previouslyAssessedTrainingItemColour || theme.accentColour || '#FF0000',
          heading: '#000000',
          light: '#FFFFFF',
          dark: '#000000',
          leftBannerIconColour: theme.leftBannerIconColour || '#000000',
          rightBannerIconColour: theme.rightBannerIconColour || '#000000',
        };
      } catch (e) {
        console.error('Failed to parse organisationConfig from localStorage:', e);
        this.theme = {}; // Fallback to empty theme
      }
    } else {
      console.warn('No organisationConfig found in localStorage. Using default theme.');
    }
  }

  /**
   * Apply styles to the element based on the provided styles and theme
   */
  private setStyles(): void {
    // Clear all inline styles to revert to default
    this.el.nativeElement.style.cssText = '';

    // Apply each style property based on the theme
    Object.keys(this._styles).forEach((key) => {
      const themeValue = this.theme[this._styles[key]];
      if (themeValue) {
        this.el.nativeElement.style[key] = themeValue;
      }
    });
  }
}
