import { mergeMap, catchError, debounceTime, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpStatusCode } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subject, throwError, from } from 'rxjs';
import * as moment from 'moment-timezone';
import { NgForage } from 'ngforage';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';
import {
  EventAssessorModel,
  EventDocument,
  EventModel,
  EventNotification,
  EventSessionModel,
  EventStatus,
  EventTrainerModel
} from '../models/event.model';
import { CreateEventModel, EditEventModel } from '../models/createEvent.model';
import { ParticipantModel, ParticipantRegistration, ParticipantStatus } from '../models/participant.model';
import { SurfGuardMemberModel } from '../models/surf-guard-member.model';
import { AssessmentStatus, CourseModel, ParticipantUnitSummaryProgress, UnitStatus } from '../models/course.model';
import { BulkAssessmentResponse, EventsViewParticipantDetails } from '../models/responses.model';
import { SortDirection } from '../models/sort-direction.enum';
import { ApiUtilsService } from '../shared/api-utils.service';
import { OfflineService } from './offline.service';
import { JwtService } from './jwt.service';
import { OrganisationService } from './organisation.service';
import { AnalyticsService } from './analytics.service';

export interface EventsQueryParams {
  myPartnershipAccounts: boolean;
  incomplete: boolean;
  myEvents: boolean;
}

export interface ExternalMemberSearchParams {
  Surname: string;
  EntityID?: number;
  FirstName?: string;
  PartialNameSearch?: boolean;
}

export interface InternalMemberSearchParams {
  lastName: string;
  firstName: string;
}

export interface AssessorsSearchParams {
  courseIds: Array<string>;
  account: { id: string; parent_name: string };
  query: string;
}

export interface Assessor {
  courses: Array<string>;
  externalSystemId: string;
  id: string;
  name: string;
}

export interface Trainer extends Assessor {
}

interface AssessorTrainerWithRole extends EventAssessorModel, EventTrainerModel {
  role: 'Assessor' | 'Trainer';
}

export interface AssessCheckRPLResponse {
  criteria: {
    trainingId: string,
    eligibleForRPL: boolean,
    errors?: {
      code: AssessCheckRPLStatusCodes,
      detail: string,
    }[]
  }[];
}

export interface MemberExtendedDetails {
  externalId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  username: string;
  email: string;
  dateOfBirth: string;
  subOrganisations: string[];
  explicitPartnershipAccounts: { department: string, subOrganisation: string }[];
  externalSystemId?: string;
}

export enum AssessCheckRPLStatusCodes {
  AWARD_RPL_SLSA = 'AWARD_RPL_SLSA',
  RPL_NOT_CONFIGURED = 'RPL_NOT_CONFIGURED'
}

export enum EventEligibilityStatusesCodes {
  isEventInThePast = 'EXPIRED_EVENT',
  isParticipantAnAssessor = 'PARTICIPANT_IS_ASSESSOR',
  isEventAtCapacity = 'CAPACITY_REACHED',
}

export enum CourseEligibilityStatusesCodes {
  hasCost = 'COST',
  isParticipantInAnotherEventForCourse = 'EXCEEDED_ENROLMENT_FOR_COURSE',
  preReq = 'PRE_REQ',
  isParticipantEnrolled = 'PARTICIPANT_ENROLLED',
  reenrolNotAllowed = 'NO_REENROLMENT_ALLOWED'
}

export const EligibilityErrorPriority = [
  'NO_REENROLMENT_ALLOWED',
  'PARTICIPANT_IS_ASSESSOR',
  'EXCEEDED_ENROLMENT_FOR_COURSE',
  'PRE_REQ',
  'COST',
  'UNKNOWN'
];

@Injectable({providedIn: 'root'})
export class EventsService {
  public blockAutoRefreshTimer: boolean;
  public selectedEventObserv: Observable<any>;
  public selectedEvent: EventModel;
  public eventsObserv: Observable<any>;
  public memberSearchObserv: Observable<any>;
  public refreshEvent: Subject<any> = new Subject<any>();
  public showMySubOrgEvents = true;
  public showIncompleteEvents = true;
  public showMyEvents = false;
  public searchingEvents = false;
  public tableSortSettings: { table: string, column: string, direction: string }[];
  private events: Array<EventModel> = [];
  private selectedEventId: string;
  private currentParams: EventsQueryParams;
  private currentMemberSearch: ExternalMemberSearchParams | InternalMemberSearchParams;
  public eventEmitter = new EventEmitter<EventModel>();
  event$ = this.eventEmitter.asObservable();

  constructor(
    private http: HttpClient,
    private ngf: NgForage,
    private offlineService: OfflineService,
    private jwtService: JwtService,
    private translateService: TranslateService,
    private organisationService: OrganisationService,
    private snackBar: MatSnackBar,
    private apiUtilsService: ApiUtilsService,
    private analytics: AnalyticsService
  ) {
    this.loadFilter();
    this.loadTableSortOptions();
  }

  public getAllEvents(qParams?: EventsQueryParams, forced = false): Observable<any> {
    if (this.eventsObserv && !forced && (qParams === this.currentParams)) {
      return this.eventsObserv;
    }
    if (this.offlineService.isOffline) {
      this.eventsObserv = new Observable((observer) => {
        (this.getCachedEvents().then((data) => {
          this.searchingEvents = false;
          observer.next(data);
          observer.complete();
        }));
      });
      return this.eventsObserv;
    } else {
      const criteria = {
        start: null,
        end: null,
        coordinates: [],
        status: []
      };
      let myPartnershipAccounts: boolean;
      let myEvents: boolean;
      const params: any = {};

      if (qParams) {
        this.currentParams = qParams;
        if (qParams.myPartnershipAccounts) {
          myPartnershipAccounts = qParams.myPartnershipAccounts;
        }

        if (qParams.myEvents) {
          myEvents = qParams.myEvents;
        }

        criteria.status = [EventStatus.IN_PROGRESS, EventStatus.SCHEDULED, EventStatus.IN_REVIEW];
        if (!qParams.incomplete) {
          criteria.status.push(EventStatus.COMPLETED);
        }
        if (myPartnershipAccounts) {
          params.myPartnershipAccounts = myPartnershipAccounts;
        }

        if (myEvents) {
          params.myEvents = myEvents;
        }

        const _clearedCriteria = this.clearEmptyKeys(criteria);
        if (Object.keys(_clearedCriteria).length > 0) {
          params.criteria = JSON.stringify(_clearedCriteria);
        }
      }
      this.eventsObserv = this.http.get(environment.api.listEvents, {params: params})
        .pipe(tap((response: any) => {
          this.searchingEvents = false;
          this.events = (response.data) ? response.data.events : (response.events) ? response.events : response;
          this.setCacheEvents(this.events);
        }), map((response: any) => {
          return (response.data) ? response.data.events : (response.events) ? response.events : [];
        }), catchError((err) => {
          this.searchingEvents = false;
          return throwError(err);
        }), shareReplay());
      return this.eventsObserv;
    }
  }

  public searchEvents(search: Observable<EventsQueryParams>, debounceMs = 500) {
    return search.pipe(
      debounceTime(debounceMs),
      distinctUntilChanged(),
      tap(() => (this.searchingEvents = true)),
      switchMap((qParams) => this.getAllEvents(qParams))
    );
  }

  public getEvent(eventId: string, forced: boolean = false): Observable<any> {
    if (this.offlineService.isOffline) {
      const event = new Observable((observer) => {
        this.getCachedEvent(eventId).then((res) => {
          if (res) {
            this.selectedEvent = new EventModel(res);
            observer.next(this.selectedEvent);
            this.eventEmitter.emit(this.selectedEvent);
          } else {
            observer.error('Offline: Event not stored');
          }
          observer.complete();
        }).catch((err) => {
          observer.error(err);
          observer.complete();
        });
      });
      return event;
    } else if (this.selectedEventObserv && eventId === this.selectedEventId && !forced) {
      return this.selectedEventObserv;
    } else {
      this.selectedEventId = eventId;
      let params = new HttpParams().set('participants', String(true)).set('courses', String(true));
      if (this.organisationService.orgHasAccountDataEnabled()) {
        params = params.set('accounts', String(true));
      }
      const url = this.apiUtilsService.setApiUrl(environment.api.singleEvent, [{key: '{id}', value: eventId}]);
      this.selectedEventObserv = this.http.get(url, {params}).pipe(
        tap((response: any) => {
          const eventData = this.mapEventData(response.data || response);
          this.selectedEvent = new EventModel(eventData);
          this.setCachedEvent(this.selectedEvent, 'selected-event');
          this.setCachedEvent(this.selectedEvent);
          this.eventEmitter.emit(this.selectedEvent);
        }),
        map(() => this.selectedEvent),
        catchError((err) => {
          if (err.status === 499) {
            const event = new Observable((observer) => {
              this.getCachedEvent(eventId).then((res) => {
                if (res) {
                  this.selectedEvent = new EventModel(res);
                  observer.next(res);
                } else {
                  observer.error('Offline: Event not stored');
                }
                observer.complete();
              }).catch((_err) => {
                observer.error(_err);
                observer.complete();
              });
            });
          } else {
            return throwError(err);
          }
        }),
        shareReplay());
      return this.selectedEventObserv;
    }
  }

  /**
   * Retrieves the event in a Promise format
   * @param eventId - ID of the event to retrieve.
   * @returns The event object.
   */
  public getPromisedEvent(eventId: string): Promise<EventModel> {
    return new Promise(resolve => {
      this.getEvent(eventId).subscribe((event: EventModel) => {
        resolve(event);
      });
    });
  }

  public clearSelectedEvent() {
    this.selectedEvent = null;
    this.selectedEventId = '';
  }

  public createEvent(event: CreateEventModel, notification: EventNotification | null): Observable<any> {
    return this.http.post(environment.api.createEvent, {...event, notification});
  }

  public updateEvent(event: EditEventModel, notification: any): Observable<any> {
    const args = [{key: '{id}', value: event.id}];
    const url = this.apiUtilsService.setApiUrl(environment.api.updateEvent, args);
    const body = {
      ...this.stripParticipants(event),
      notification
    };
    return new Observable((observer) => {
      this.http.put(url, body).subscribe((response) => {
        observer.next(response);
        observer.complete();

        void this.analytics.track('Event Updated', {
          eventId: event.id
        });
      }, (err) => {
        let message = err.message || err;
        if (err.status === 404) {
          message = `This event isn't available to update`;
        }

        observer.error(message);
        observer.complete();

        void this.analytics.track('Event UpdateFailed', {
          eventId: event.id,
          error: message
        });
      });
    });
  }

  public cancelEvent(eventId: string, eventName: string, cancellationReason?: string, notification?): Observable<any> {
    const args = [{key: '{id}', value: eventId}];
    const url = this.apiUtilsService.setApiUrl(environment.api.cancelEvent, args);
    const body = {
      name: eventName,
      cancellationReason
    };
    if (typeof notification !== 'undefined') {
      body['notification'] = notification;
    }
    return new Observable((observer) => {
      this.http.post(url, body).subscribe((response) => {
        observer.next(response);
        observer.complete();

        void this.analytics.track('Event Cancelled', {
          eventId,
          eventName,
          cancellationReason
        });
      }, (err) => {
        let message = err.message || err;
        if (err.status === 404) {
          message = `This event isn't available to cancel`;
        }

        observer.error(message);
        observer.complete();

        void this.analytics.track('Event CancelFailed', {
          eventId,
          eventName,
          error: message
        });
      });
    });
  }

  /**
   * Approve an event
   * @param {EditEventModel} event - the event to approve
   * @returns {Observable} the response from the api
   */
  public approveEvent(event: EditEventModel): Observable<any> {
    const args = [{key: '{id}', value: event.id}];
    const url = this.apiUtilsService.setApiUrl(environment.api.approveEvent, args);
    return this.http.post(url, event)
      .pipe(tap(() => {
        void this.analytics.track('Event Approved', {
          eventId: event.id
        });
      }));
  }

  /**
   * Reject an event
   * @param {EditEventModel} event - the event to reject
   * @param {string} eventId - the id of the event to reject
   * @returns {Observable} the response from the api
   */
  public rejectEvent(event: EventModel, eventId: string): Observable<any> {
    const args = [{key: '{id}', value: eventId}];
    const url = this.apiUtilsService.setApiUrl(environment.api.rejectEvent, args);
    return this.http.post(url, event)
      .pipe(tap(() => {
        void this.analytics.track('Event Rejected', {
          eventId
        });
      }));
  }


  /**
   * Get all the assessor and trainer ids for an event
   * @param event
   * @returns string[] - array of ids
   */
  public getAssessorAndTrainerIds(event: CreateEventModel | EditEventModel | EventModel): string[] {
    const ids: string[] = [];
    event.sessions.forEach((_session) => {
      ids.push(..._session.assessors.map((_assessor) => (_assessor.id)));
      ids.push(..._session.trainers.map((_trainer) => (_trainer.id)));
    });
    return [...new Set(ids)];
  }

  public getParticipantContacts() {
    const excludedStatuses = [ParticipantStatus.CANCELLED, ParticipantStatus.WAITLISTED];
    const participantIds: string[] = [
      ...this.selectedEvent.participants
        .filter(participant => !excludedStatuses.includes(participant.status))
        .map(participant => participant.id)
    ];
    return participantIds;
  }

  /**
   * Get an array of all the participant ids for an event (Event Model event objects only).
   * @param event - EventModel
   * @returns string[] - array of ids
   */
  public getParticipantContactsFromEventModel(event: EventModel): string[] {
    return event.participants
      .filter(participant => ![ParticipantStatus.CANCELLED, ParticipantStatus.WAITLISTED].includes(participant.status))
      .map(participant => participant.id);
  }

  public getExternalMembers(search: ExternalMemberSearchParams): Observable<any> {
    if (this.memberSearchObserv && (this.currentMemberSearch === search)) {
      return this.memberSearchObserv;
    } else {
      this.currentMemberSearch = search;
      this.memberSearchObserv = this.http.post(environment.api.externalMemberSearch, search).pipe(
        catchError((err) => {
          return of([]);
        }),
        shareReplay()
      );
      return this.memberSearchObserv;
    }
  }

  public externalMemberSearch(member: Observable<ExternalMemberSearchParams>, debounceMs = 500) {
    return member.pipe(
      debounceTime(debounceMs),
      distinctUntilChanged(),
      switchMap((names) => this.getExternalMembers(names))
    );
  }

  public getInternalMembers(search: InternalMemberSearchParams): Observable<any> {
    if (this.memberSearchObserv && (this.currentMemberSearch === search)) {
      return this.memberSearchObserv;
    } else {
      this.currentMemberSearch = search;
      const params = new HttpParams().set('firstName', search.firstName).set('lastName', search.lastName);
      this.memberSearchObserv = this.http.get(environment.api.internalMemberSearch, {params}).pipe(
        catchError((err) => {
          console.error(err);
          if (err.status && err.status === 404) {
            return of([]);
          } else {
            return throwError(err);
          }
        }), shareReplay());
      return this.memberSearchObserv;
    }
  }

  public internalMemberSearch(member: Observable<InternalMemberSearchParams>, debounceMs = 500) {
    return member.pipe(
      debounceTime(debounceMs),
      distinctUntilChanged(),
      switchMap((names) => this.getInternalMembers(names))
    );
  }

  public findCreateMember(body: SurfGuardMemberModel) {
    return this.http.post(environment.api.findCreateMember, {...body, orgId: this.jwtService.getContextOrg().id});
  }

  public getAssessors(courseIds: Array<string>, account, query) {
    let assessors = [];
    let responseCount = 0;
    return new Observable((observer) => {
      courseIds.forEach((_cid) => {
        this.http.get(environment.api.getAssessors, {
          params: {courseIds: JSON.stringify([_cid]), account: JSON.stringify(account), query: query}
        }).pipe(
          catchError((err) => {
            if (err.status >= 500) {
              return of({error: true, message: `Something didn't work there, try again.`});
            } else if (err.status === 404) {
              return of({error: true, message: `No assessors found for selected course(s)`});
            } else {
              return of({error: true, message: err.message || err});
            }
          }),
          shareReplay()
        ).subscribe((response) => {
          assessors = assessors.concat(response);
          responseCount++;
          if (responseCount === courseIds.length) {
            observer.next(assessors);
            observer.complete();
          }
        }, (err) => {
          observer.error(err.message || err);
          observer.complete();
        });
      });
    });
  }

  public loadAssessors(assessor: Observable<AssessorsSearchParams>) {
    return assessor.pipe(
      distinctUntilChanged(),
      switchMap((data) => this.getAssessors(data.courseIds, data.account, data.query))
    );
  }

  public getEventEligibilityWarnings(eventId, courses): Observable<Array<String>> {
    return from(this.getEventEligibility(eventId, courses)).pipe(
      mergeMap(data => {
        const warnings = [];
        Object.keys(data.criteria).forEach(statusCode => {
          if (statusCode === 'courses') {
            return;
          } else {
            if (data.criteria[statusCode] === true) {
              warnings.push(EventEligibilityStatusesCodes[statusCode] ? EventEligibilityStatusesCodes[statusCode] : 'UNKNOWN');
            }
          }
        });
        return of(warnings);
      }));
  }

  public getEventEligibility(eventId, courses): Observable<any> {
    const courseIds = courses.map(course => course.id);
    const args = [{key: '{id}', value: eventId}, ];
    return this.http.get(this.apiUtilsService.setApiUrl(`${environment.api.eventEligibility}?courses=["${courseIds.join('","')}"]`, args));
  }

  public getCreditEligibility(eventId: string, participantID: string, unitId: string): Promise<AssessCheckRPLResponse> {
    return new Promise((resolve, reject) => {
      const args = [{key: '{id}', value: eventId}, {key: '{participantId}', value: participantID}];
      const url = this.apiUtilsService.setApiUrl(environment.api.creditEligibility, args);
      this.http.post(url, {
        trainingIds: [unitId]
      }).subscribe((response: AssessCheckRPLResponse) => {
        response.criteria.forEach((credit) => {
          // credit.eligibleForRPL = true;
          if (!credit.hasOwnProperty('errors')) {
            credit['errors'] = [{
              code: AssessCheckRPLStatusCodes.RPL_NOT_CONFIGURED,
              detail: 'No RPL configured for this assessment'
            }];
          }
        });
        resolve(response);
      }, (e) => {
        reject(e);
      });
    });
  }

  public registerParticipant(registerInfo: ParticipantRegistration, eventId: string, sessionId: string = '') {
    const obj = registerInfo.get();
    if (obj.participant.id === '') {
      delete obj.participant.id;
    }

    return from(this.registerParticipantCall(registerInfo, eventId, sessionId)).pipe(
      catchError((err) => {
        if (typeof err === 'string') {
          return throwError(err);
        } else {
          return throwError(this.getErrorMessageForUserEnrolRegister(err));
        }
      }),
    );
  }

  public registerParticipantCall(registerInfo: ParticipantRegistration, eventId: string, sessionId?: string) {
    const args = [{key: '{id}', value: eventId}, {key: '{sessionId}', value: sessionId}];
    const url = this.apiUtilsService.setApiUrl(environment.api.signInParticipant, args);
    return this.http.post(url, registerInfo.get(), {
      params: {
        autoMarkCompetent: this.selectedEvent.advanced.formDefaults.autoMarkCompetent.toString()
      }
    }).pipe(
      tap((response: any) => {
        if (sessionId === '') {
          this.selectedEvent.addParticipant(response.data);
          void this.analytics.track('Participant Registered', {
            participantId: response.data.participant.id,
            eventId
          });
        } else {
          this.selectedEvent.updateParticipant(response.data);
          void this.analytics.track('Participant RegistrationUpdated', {
            participantId: response.data.participant.id,
            eventId
          });
        }
      }),
      map((response: any) => {
        return response.data;
      }),
      catchError((err) => {
        if (err.status === 499) {
          const offlineParticipantResponse = this.offlineParticipantUpdate(registerInfo);
          this.selectedEvent.updateParticipant(offlineParticipantResponse);
          this.setCachedEvent(this.selectedEvent);
          void this.analytics.track('Participant RegistrationFailed', {
            participantId: offlineParticipantResponse.participant?.id,
            eventId,
            reason: 'offline'
          });
          return of(offlineParticipantResponse);
        } else {
          const message = this.getErrorMessageForUserEnrolRegister(err);
          void this.analytics.track('Participant RegistrationFailed', {
            participantId: registerInfo?.participant?.id,
            eventId,
            reason: message
          });
          return throwError(message);
        }
      })
    );
  }

  public enrolParticipant(enrolData, eventId: string) {
    const args = [{key: '{id}', value: eventId}];
    const url = this.apiUtilsService.setApiUrl(environment.api.enrolParticipant, args);
    return this.http.post(url, enrolData).pipe(
      tap((response: any) => {
        this.selectedEvent.addParticipant(response.data);
        void this.analytics.track('Participant Enrolled', {
          participantId: response.data.participant.id,
          eventId
        });
      }),
      map((response: any) => (response.data)),
      catchError((err) => {
        return throwError(this.getErrorMessageForUserEnrolRegister(err));
      })
    );
  }

  public removeParticipant(eventId: string, participantId: string) {
    const args = [{key: '{id}', value: eventId}, {key: '{participantId}', value: participantId}];
    const url = this.apiUtilsService.setApiUrl(environment.api.removeParticipant, args);
    return this.http.delete(url).pipe(
      tap(() => {
        void this.analytics.track('Participant Removed', {
          participantId,
          eventId
        });
      }),
      catchError((err) => {
        if (err.status === 499) {
          // offline
          this.setCachedEvent(this.selectedEvent);
          return of({});
        } else {
          return throwError(err);
        }
      })
    );
  }

  public updateStatus(statusUpdate, eventId, participantId): Observable<any> {
    if (statusUpdate.supplementaryInformation && statusUpdate.supplementaryInformation.length === 0) {
      delete statusUpdate.supplementaryInformation;
    }
    const url = this.apiUtilsService.setApiUrl(environment.api.updateAssessmentStatus,
      [{key: '{id}', value: eventId}, {key: '{participantId}', value: participantId}]);
    return this.http.post(url, statusUpdate).pipe(
      tap((response: any) => {
        const unit = response.data;
        this.selectedEvent.updateParticipantUnit(participantId, unit);
        void this.analytics.track('Assessment StatusChanged', {
          participantId,
          eventId,
          unitId: unit.unitId,
          status: unit.status
        });
      }),
      map((response: any) => {
        // do anything in regards to mapping response
        return response.data;
      }),
      catchError((err) => {
        if (err.status && err.status === 499) {
          // offline mode
          const unit = this.selectedEvent.updateParticipantLearningAssessmentInstance(
            participantId,
            statusUpdate.unitInstances[0].instanceId,
            statusUpdate.learningAssessmentInstances[0].instanceId, statusUpdate.status
          );
          const response = {
            id: unit.id,
            status: statusUpdate.status,
            unitId: unit.unitId,
            learningAssessments: [unit.learningAssessments.find((_la) => (_la.id === statusUpdate.learningAssessmentInstanceId))]
          };
          this.setCachedEvent(this.selectedEvent);
          return of(response);
        } else {
          return throwError(err);
        }
      })
    );
  }

  public saveEventDocument(eventId: string, document: EventDocument): Observable<any> {
    const args = [{key: '{id}', value: eventId}];
    const url = this.apiUtilsService.setApiUrl(environment.api.saveEventDocument, args);
    return this.http.post(url, {document});
  }

  bulkUpdateStatus(
    participantIds: string[],
    status: AssessmentStatus,
    unitId: string,
    unitInstances: any[],
    learningAssessmentId: string,
    learningAssessmentInstances: any[],
    courseId: string,
  ) {
    const url = this.apiUtilsService.setApiUrl(environment.api.bulkUpdateAssessmentStatus, [{key: '{id}', value: this.selectedEvent._id}]);
    const body = {
      status,
      unitId,
      unitInstances,
      learningAssessmentId,
      learningAssessmentInstances,
    };

    return this.http.post(url, body).pipe(
      tap((response: any) => {
        this.selectedEvent.bulkUpdateParticipantLearningAssessmentInstance(
          response.data,
          courseId,
          unitInstances.map(ui => ui.instanceId), learningAssessmentInstances.map(lai => lai.instanceId)
        );
        // update local model of selected event
      }), map((response: any) => {
        this.analytics.track('Assessment BulkStatusChanged', {
          eventId: this.selectedEvent._id,
          courseId,
          unitId,
          participantIds,
          status
        });
        return response.data;
      }), catchError((err) => {
        // catch an error, check if its an offline error and update local model
        if (err.status && err.status === 499) {
          const response = this.offlineBulkAssessmentResponse(body, courseId);
          this.selectedEvent.bulkUpdateParticipantLearningAssessmentInstance(
            response,
            courseId,
            unitInstances.map(ui => ui.instanceId), learningAssessmentInstances.map(lai => lai.instanceId)
          );
          this.setCachedEvent(this.selectedEvent);
          return of(null);
        } else {
          return throwError(err);
        }
      })
    );
  }

  public markEventComplete() {
    const {courses, participants} = this.selectedEvent;
    const url = this.apiUtilsService.setApiUrl(environment.api.completeEvent, [{key: '{id}', value: this.selectedEvent._id}]);
    if (this.selectedEvent.eventFeedback && this.selectedEvent.eventFeedback.url) {
      this.getFeedbackUrlParams();
    }
    return this.http.post(url, {
      courses,
      participants,
      feedbackUrlParams: this.selectedEvent.eventFeedback && this.selectedEvent.eventFeedback.url ? this.selectedEvent.eventFeedback.params : null
    }).pipe(
      tap(() => {
        void this.analytics.track('Event StatusChanged', {
          eventId: this.selectedEvent._id,
          status: this.selectedEvent.status
        });
      }),
      catchError((err) => {
        return throwError('Something went wrong');
      })
    );
  }

  public markEventReadyForReview(): Observable<any> {
    if (this.selectedEvent.eventFeedback && this.selectedEvent.eventFeedback.url) {
      this.getFeedbackUrlParams();
    }

    const args = [{key: '{id}', value: this.selectedEvent._id}];
    const url = this.apiUtilsService.setApiUrl(environment.api.reviewEvent, args);

    this.formatExpectedDocuments();

    return this.http.post(url,
      {...this.selectedEvent, status: EventStatus.IN_REVIEW},
    ).pipe(
      tap(({statusCode}: any) => {
        // only update the local model of the event when the request is complete
        if (statusCode === HttpStatusCode.Ok) {
          this.selectedEvent.status = EventStatus.IN_REVIEW;
          void this.analytics.track('Event StatusChanged', {
            eventId: this.selectedEvent._id,
            status: this.selectedEvent.status
          });
        }
      }),
      catchError((err) => {
        return throwError('Something went wrong');
      })
    );
  }


  public markEventInProgress(parentLocationId: string) {
    this.selectedEvent.status = EventStatus.IN_PROGRESS;
    this.selectedEvent.accounts[0].location.parent_externalId = parentLocationId;
    this.selectedEvent.reviewStages.currentReviewer = 1;

    const args = [{key: '{id}', value: this.selectedEvent._id}];
    const url = this.apiUtilsService.setApiUrl(environment.api.requestChanges, args);
    return this.http.post(url,
      this.selectedEvent,
    ).pipe(
      tap(() => {
        void this.analytics.track('Event StatusChanged', {
          eventId: this.selectedEvent._id,
          status: this.selectedEvent.status
        });
      }),
      catchError((err) => {
        return throwError('Something went wrong');
      })
    );
  }

  public nextReviewStage() {
    const {reviewStages} = this.selectedEvent;
    const numberOfReviewStages = reviewStages && reviewStages.reviewers.length ? Math.max(...reviewStages.reviewers.map(reviewer => reviewer.reviewerOrder)) : 0;
    // If the current reviewer is less than the number of reviewers stages, increment the current reviewer
    if (this.selectedEvent.reviewStages.currentReviewer < numberOfReviewStages) {
      this.selectedEvent.reviewStages.currentReviewer += 1;
    }

    this.formatExpectedDocuments();

    const args = [{key: '{id}', value: this.selectedEvent._id}];
    const url = this.apiUtilsService.setApiUrl(environment.api.reviewEvent, args);
    return this.http.post(url,
      this.selectedEvent,
    ).pipe(
      tap(() => {
        void this.analytics.track('Event StatusChanged', {
          eventId: this.selectedEvent._id,
          status: this.selectedEvent.status
        });
      }),
      catchError((err) => {
        return throwError('Something went wrong');
      })
    );
  }

  public getEventStatus() {
    const url = this.apiUtilsService.setApiUrl(environment.api.summary, [{key: '{id}', value: this.selectedEvent._id}]);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((err) => {
        return throwError('Something went wrong');
      })
    );
  }

  public sendNotification(body): Observable<any> {
    const url = environment.api.sendNotification;
    return this.http.post(url, body).pipe(
      catchError((err) => {
        return throwError('Something went wrong');
      })
    );
  }

  public saveComment(parentLocationId: string): Observable<any> {
    this.selectedEvent.accounts[0].location.parent_externalId = parentLocationId;

    const args = [{key: '{id}', value: this.selectedEvent._id}];
    const url = this.apiUtilsService.setApiUrl(environment.api.saveComment, args);
    return this.http.post(url, this.selectedEvent).pipe(
      tap(() => {
        void this.analytics.track('Event CommentSaved', {
          eventId: this.selectedEvent._id,
        });
      }),
      catchError((err) => {
        return throwError('Something went wrong');
      })
    );
  }

  public executeUpdate() {
    this.refreshEvent.next();
  }

  public toggleShowClubEvents() {
    this.showMySubOrgEvents = !this.showMySubOrgEvents;
    this.updateFilterLocalStorage();
  }

  public toggleshowIncompleteEvents() {
    this.showIncompleteEvents = !this.showIncompleteEvents;
    this.updateFilterLocalStorage();
  }

  public toggleShowMyEvents() {
    this.showMyEvents = !this.showMyEvents;
    this.updateFilterLocalStorage();
  }

  public loadFilter() {
    const filterOptions = JSON.parse(localStorage.getItem('filterSettings'));
    if (filterOptions !== null) {
      this.showMySubOrgEvents = filterOptions.showMySubOrgEvents;
      this.showMyEvents = filterOptions.showMyEvents;
      this.showIncompleteEvents = filterOptions.showIncompleteEvents;
    }
  }

  public loadTableSortOptions() {
    const stored = localStorage.getItem('tableSortSettings');
    this.tableSortSettings = (stored) ? JSON.parse(stored) : [];
  }

  public saveTableSortOptions() {
    localStorage.setItem('tableSortSettings', JSON.stringify(this.tableSortSettings));
  }

  public getTableSortOption(tableName: string): { table: string, column: string, direction: SortDirection } {
    if (!this.tableSortSettings) {
      return null;
    }
    const sortOption = this.tableSortSettings.find((_t) => (_t.table === tableName));
    if (sortOption) {
      return {
        table: sortOption.table,
        column: sortOption.column,
        direction: (sortOption.direction === SortDirection.ASC) ? SortDirection.ASC : SortDirection.DESC
      };
    } else {
      return null;
    }
  }

  public setTableSortOption(tableName: string, column: string, direction: SortDirection) {
    const existing = this.tableSortSettings.find((_ts) => (_ts.table === tableName));
    if (existing) {
      existing.column = column;
      existing.direction = direction;
    } else {
      this.tableSortSettings.push({
        table: tableName,
        column: column,
        direction: direction
      });
    }
    this.saveTableSortOptions();
  }

  public updateFilterLocalStorage() {
    const filterOptions = {
      'showMySubOrgEvents': this.showMySubOrgEvents,
      'showMyEvents': this.showMyEvents,
      'showIncompleteEvents': this.showIncompleteEvents
    };
    localStorage.setItem('filterSettings', JSON.stringify(filterOptions));
  }

  public eventAdminAvailable(status?: EventStatus): boolean {
    status = (typeof status === 'undefined') ? this.selectedEvent.status : status;
    return status !== EventStatus.COMPLETED && !this.offlineService.isOffline;
  }

  public canUserAssess(includeTrainers: boolean = false) {

    const assessor = this.selectedEvent.eventAssessors.filter((_a: EventAssessorModel) => {
      return _a.id === this.jwtService.getUserId();
    });
    const trainer = this.selectedEvent.eventTrainers.filter((_t: EventTrainerModel) => {
      return _t.id === this.jwtService.getUserId();
    });
    return assessor.length > 0 || (includeTrainers && trainer.length > 0);
  }

  private async setCachedEvent(event: EventModel, key?: string) {
    const data = {
      body: event,
      date: moment().format(),
      type: 'event',
      request: false,
      failed: false
    };
    await this.ngf.setItem(key || `event-${event._id}`, data);
  }

  public async getCachedEvent(eventId?: string) {
    let storedEvent = null;
    if (typeof eventId === 'undefined') {
      storedEvent = await this.ngf.getItem(`selected-event`);
    } else {
      storedEvent = await this.ngf.getItem(`event-${eventId}`);
    }
    return (storedEvent) ? storedEvent.body : null;
  }

  private async setCacheEvents(events: Array<EventModel>) {
    const data = {
      body: events,
      date: moment().format(),
      type: 'events',
      request: false,
      failed: false
    };
    await this.ngf.setItem('events', data);
  }

  private async getCachedEvents(): Promise<any> {
    const storedEvents: any = await this.ngf.getItem('events');
    return storedEvents.body;
  }

  /**
   * This method helps with keeping old event data inline with the new model/expected data
   * Use when breaking changes occur in the event model
   * @param response - event data from the api
   * @returns event data that is mapped to maintain consistent data
   */
  private mapEventData(response): any {
    response.courses.forEach((course) => {
      course.units.forEach((unit) => {
        unit.learningAssessments.forEach((learningAssessment) => {
          learningAssessment.supplementaryInfo.forEach((suppInfo) => {
            suppInfo.assessmentFieldTypeId = suppInfo.fieldTypeSchema.type.name;
          });
        });
      });
    });
    // map the users timezone if no timezone is set on a session
    response.sessions.forEach((_session) => {
      _session.timezone = _session.timezone || this.getUserTimezone();
    });
    return response;
  }

  private clearEmptyKeys(obj: any): any {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object') {
          obj[key] = this.clearEmptyKeys(obj[key]);
        }
        if (obj[key] === false || obj[key] === null || obj[key] === '' || obj[key].length < 1) {
          delete obj[key];
        }
      }
    }

    return obj;
  }

  private offlineParticipantUpdate(registrationInfo: ParticipantRegistration) {
    const unitStatuses = [];
    this.selectedEvent.courses.forEach((_c: CourseModel) => {
      const unit = {
        courseId: _c.id,
        participants: []
      };
      _c.participants.forEach((_p) => {
        const participant = {
          id: _p.id,
          units: this.selectedEvent.getCourseParticipant(_p.id, _c.id).units
        };
        unit.participants.push(participant);
      });
      unitStatuses.push(unit);
    });
    return {
      participant: {
        id: registrationInfo.participant.id,
        name: `${registrationInfo.participant.firstName} ${registrationInfo.participant.lastName}`,
        courses: registrationInfo.courses
      },
      event: {
        id: this.selectedEvent._id,
        name: this.selectedEvent.name
      },
      _id: registrationInfo.sessionRegistrations.sessionId,
      dateUpdated: new Date(),
      status: ParticipantStatus.REGISTERED,
      sessionRegistrations: [registrationInfo.sessionRegistrations],
      courses: registrationInfo.courses,
      unit_statuses: unitStatuses
    };
  }

  private offlineBulkAssessmentResponse(requestBody, courseId: string): BulkAssessmentResponse {
    const response = {
      units: [],
      assessment: {
        learningAssessmentId: requestBody.learningAssessmentId,
        status: requestBody.status
      }
    };
    requestBody.unitInstances.forEach((unitInstance) => {
      response.units.push({
        id: unitInstance.instanceId,
        unitId: requestBody.unitId,
        status: requestBody.status,
        assessmentActivitySummary: unitInstance.assessmentActivitySummary
      });
    });
    return response;
  }

  private calculateUnitStatus(
    participantId: string,
    unitId: string,
    courseId: string,
    learningAssessmentId: string,
    status: AssessmentStatus): UnitStatus {
    let unitStatus = UnitStatus.COMPLETE;
    const courseParticipantUnit = this.selectedEvent.getCourseParticipantUnit(participantId, unitId, courseId);
    courseParticipantUnit.learningAssessments.forEach((_la) => {
      if (_la.learningAssessmentId === learningAssessmentId) {
        if (status === AssessmentStatus.IN_PROGRESS) {
          unitStatus = UnitStatus.IN_PROGRESS;
        }
      } else if (_la.status === AssessmentStatus.IN_PROGRESS) {
        unitStatus = UnitStatus.IN_PROGRESS;
      }
    });
    return unitStatus;
  }

  public stripParticipants(event) {
    event.courses.forEach(i => {
      delete i.participants;
    });
    return event;
  }

  public offlineEventModelUpdate(emitterPayload) {
    // Check selectedEvent Model to keep the table upto date
    this.selectedEvent.courses.forEach(course => {
      const participant = course.participants[course.participants
        .findIndex(_participant => _participant.id === emitterPayload.participantIds[0])];
      if (!!participant) {
        const participantUnits = participant.units[participant.units
          .findIndex(_unit => _unit.unitId === emitterPayload.unitId)];
        emitterPayload.learningAssessmentInstances.forEach(_learningAssessment => {
          participantUnits.learningAssessments[participantUnits.learningAssessments
            .findIndex(_assessment => _assessment.id === _learningAssessment.instanceId)].status = emitterPayload.status;
        });

        // Check if all learning assessments have been marked to update the unit checkbox
        participantUnits.status = participantUnits.learningAssessments
          .find(_learningAssessment => _learningAssessment.status.toString() === UnitStatus.IN_PROGRESS)
          ? UnitStatus.IN_PROGRESS : UnitStatus.COMPLETE;
      }
    });
  }

  public getEventAssessors(event: EventModel): Array<Assessor> {
    const _assessors = [];
    event.sessions.map(session => {
      session.assessors.map(assessor => {
        _assessors.push(assessor);
      });
    });
    return _assessors;
  }

  public getEventTrainers(event: EventModel): Array<Trainer> {
    const _trainers = [];
    event.sessions.forEach(session => {
      session.trainers?.forEach(trainer => {
        _trainers.push(trainer);
      });
    });
    return _trainers;
  }

  public getEventParticipantsCSV(event: EventModel, status?: ParticipantStatus[]): Observable<any> {
    let url = this.apiUtilsService.setApiUrl(environment.api.participantDetails, [
      {key: '{id}', value: event._id},
      {key: '{outputType}', value: 'csv'},
    ]);

    if (status && status.length > 0) {
      const statusParam = JSON.stringify(status);
      url += `&status=${encodeURIComponent(statusParam)}`;
    }
    return this.http.get(url, {responseType: 'text'});
  }

  /**
   * Requests a PDF attendance sheet file from the server for the given event ID.
   * @param eventId - ID of event to get attendance sheet for.
   * @returns - Observable with response.
   */
  public getAttendanceSheetPDF(eventId: string): Observable<any> {
    const url: string = this.apiUtilsService.setApiUrl(environment.api.attendanceSheet, [
      {key: '{id}', value: eventId}
    ]);
    return this.http.get(url, {responseType: 'text'});
  }

  /**
   * Request a PDF compliance report file from the server for the given event ID.
   * @param eventId - ID of the event to get compliance report for.
   * @returns - Observable with response.
   */
  public getComplianceReportPDF(eventId: string): Observable<any> {
    const url: string = this.apiUtilsService.setApiUrl(environment.api.complianceReport, [
      {key: '{eventId}', value: eventId}
    ]);
    const params = new HttpParams().set('audit', String(true)).set('participants', String(true)).set('courses', String(true));
    return this.http.get(url, {params, responseType: 'text'});
  }

  public getMemberExtendedDetails(memberId: string): Observable<MemberExtendedDetails> {
    const url = this.apiUtilsService.setApiUrl(environment.api.getParticipantDetails, [
      {key: '{id}', value: this.selectedEvent._id},
      {key: '{memberId}', value: memberId}
    ]);
    return this.http.get(url).pipe(
      map((response: EventsViewParticipantDetails) => {
        return {
          ...response.user,
          dateOfBirth: response.user.dateOfBirth ? moment(response.user.dateOfBirth, 'YYYY-MM-DD').format('Do MMMM YYYY') : 'Not Available',
          subOrganisations: response.explicitPartnershipAccounts.filter((account) => (!!account.name)).map((account) => (account.name)),
          explicitPartnershipAccounts: response.explicitPartnershipAccounts.filter((account) => (!!account.name)).map((account) => ({
            subOrganisation: account.name,
            department: account.ancestors.find((ancestor) => (ancestor.hierarchyLevel === -1)).name
          })),
        };
      })
    );
  }

  private getErrorMessageForUserEnrolRegister(err): string {

    let message = '';
    this.translateService.get('snackbarMessages').subscribe((snackBarMessages) => {
      switch (err.status) {
        case 403:
          this.translateService.get('snackbarMessages.eligibilities').subscribe((eligibilitiesErrorCodes) => {
            message = eligibilitiesErrorCodes[err.error.message.code] || err.error.message.detail;
          });
          break;
        case 404:
          message = snackBarMessages.participantNotFound;
          break;
        case 409:
          message = snackBarMessages.participantAlreadyRegistered;
          break;
        default:
          message = snackBarMessages.default;
          break;
      }
    });
    return message;
  }

  public getEventName(): string {
    if (this.selectedEvent.eventCode) {
      return `${this.selectedEvent.name} ${'(' + this.selectedEvent.eventCode + ')'}`;
    } else {
      return this.selectedEvent.name;
    }
  }

  public getUserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || null;
  }

  public parseEnrolmentQuestions(answers: any, termsAndConditions: boolean) {
    return [
      {
        questionText: 'Do you speak a language other than English at home?',
        questionType: 'single',
        responses: [
          {
            responseType: 'boolean',
            responseText: answers.language.useOtherLanguage ? 'Yes' : 'No',
            responseValue: answers.language.useOtherLanguage,
          },
        ],
      },
      {
        questionText: 'If Yes, please specify',
        questionType: 'text',
        responses: [
          {
            responseType: 'string',
            responseText: answers.language.otherLanguage,
            responseValue: answers.language.otherLanguage,
          },
        ],
      },
      {
        questionText: 'How well do you speak English?',
        questionType: 'single',
        responses: [
          {
            responseType: 'string',
            responseText: answers.language.englishSpeakingLevel,
            responseValue: answers.language.englishSpeakingLevel,
          },
        ],
      },
      {
        questionText: 'Do you consider yourself to have a disability, impairment or long-term condition that may affect your studies?',
        questionType: 'toggle',
        responses: [
          {
            responseType: 'boolean',
            responseText: answers.disability.hasDisability ? 'Yes' : 'No',
            responseValue: answers.disability.hasDisability,
          },
        ],
      },
      {
        questionText: 'Please indicate the area of the condition:',
        questionType: 'multiple',
        responses: [
          {
            responseType: 'string',
            responseText: answers.disability.disabilityType,
            responseValue: answers.disability.disabilityType,
          },
        ],
      },
      {
        questionText: 'Please indicate other area of the condition:',
        questionType: 'text',
        responses: [
          {
            responseType: 'string',
            responseText: answers.disability.otherDisabilityType,
            responseValue: answers.disability.otherDisabilityType,
          },
        ],
      },
      {
        questionText: 'Do you wish to apply for Recognition of Prior Learning (RPL)?',
        questionType: 'toggle',
        responses: [
          {
            responseType: 'boolean',
            responseText: answers.applyForRPL ? 'Yes (please discuss with your trainer)' : 'No',
            responseValue: answers.applyForRPL,
          },
        ],
      },
      {
        questionText: 'Please accept these policy and training declaration to complete enrolment:',
        questionType: 'checkbox',
        responses: [
          {
            responseType: 'boolean',
            responseText: '',
            responseValue: termsAndConditions,
          },
        ],
      }
    ];
  }

  /**
   * Gets the Assessors and Trainers for a session.
   * @param session - The session to get assessors and trainers for.
   * @return - An array of assessor and trainer objects.
   */
  public getAssessorsAndTrainers(session: EventSessionModel): (EventAssessorModel | EventTrainerModel)[] {
    return [...new Set([...session.assessors, ...session.trainers])];
  }

  /**
   * Formats expected documents so that uploadedBy is just the id string if it is an object.
   */
  private formatExpectedDocuments(): void {
    this.selectedEvent.documents.map(document => {
      if (typeof document.uploadedBy === 'object' && document.uploadedBy !== null) {
        document.uploadedBy = document.uploadedBy.id;
      }
      return document;
    });
  }

  /**
   * Return true if the event has any reviewers/review stages.
   * @returns {boolean}
   */
  public hasReviewers(): boolean {
    return !!this.selectedEvent.reviewStages.reviewers.length;
  }

  /**
   * Gets custom parameters to be added to feedback url
   */
  private getFeedbackUrlParams(): void {
    const {organisation, region, location} = this.selectedEvent.accounts[0];
    const courses = this.selectedEvent.courses;

    // Generate the courseName param. If there is only one course, use that. Otherwise, use the course name that includes 'Skills Maintenance'.
    let courseName;
    if (courses.length === 1) {
      courseName = courses[0].name;
    } else if (courses.find(course => course.name.includes('Skills Maintenance'))) {
      courseName = 'Skills Maintenance';
    }

    this.translateService.get('accountHierarchy').subscribe(accounts => {
      this.selectedEvent.eventFeedback.params = {
        [accounts['organisation']['single'].toLowerCase()]: organisation.name,
        [accounts['department']['single'].toLowerCase()]: region.name,
        [accounts['subOrganisation']['single'].toLowerCase()]: location.name,
        ...(courseName && {courseName}),
      };
    });
  }

  /**
   * Generates a string of extra participant data to display in the participant name column
   * @param participant
   * @param prefix - string to placed before the extra information
   * @param suffix - string to be placed after the extra information
   * @returns String to display in participant name column
   */
  public getParticipantProfileDisplay(participant: ParticipantModel, prefix = '', suffix = ''): string {
    const {externalSystemId, explicitPartnershipAccounts} = participant;
    const config = this.organisationService.organisation?.eventConfig?.participantProfileDisplayAttributes;

    const result = [
      ...(config?.externalSystemId ? [externalSystemId] : []),
      ...(config?.explicitPartnershipAccounts ? explicitPartnershipAccounts?.map(account => account.parent_name) : []),
      ...(config?.explicitPartnershipAccountAncestors ? explicitPartnershipAccounts?.map(account => account.ancestors?.find(account => account.hierarchyLevel == -1)?.name) : []),
    ].filter(Boolean);
    return result.length ? `${prefix}(${result.join(', ')})${suffix}` : '';
  }

  /**
   * Marks all assessments as complete for a participant in a course.
   * @param participantId
   * @param courseId
   */
  public markAllAssessmentsAsComplete(participantId: string, courseId: string): Observable<any> {
    const url = this.apiUtilsService.setApiUrl(environment.api.markAllAssessmentsAsComplete, [
      {key: '{id}', value: this.selectedEvent._id},
      {key: '{participantId}', value: participantId},
      {key: '{courseId}', value: courseId}
    ]);
    return this.http.post(url, {}).pipe(
      switchMap(() => this.getEvent(this.selectedEvent._id, true))
    );
  }

  /**
   * Resets all assessments for a participant in a course.
   * @param participantId
   * @param courseId
   */
  public resetAllAssessments(participantId: string, courseId: string): Observable<any> {
    const url = this.apiUtilsService.setApiUrl(environment.api.resetAllAssessments, [
      {key: '{id}', value: this.selectedEvent._id},
      {key: '{participantId}', value: participantId},
      {key: '{courseId}', value: courseId}
    ]);
    return this.http.post(url, {}).pipe(
      switchMap(() => this.getEvent(this.selectedEvent._id, true))
    );
  }

  /**
   * Creates an enrolment link to the LMS calendar for the selected event
   * @returns {string} The enrolment link for the selected event
   */
  public getEnrolmentLink(): string {
    return `${this.organisationService.organisation.eventConfig.eventEnrolmentLink[environment.env]}${this.selectedEvent._id}`;
  }

  /**
   * Copies the enrolment link to the clipboard
   * @returns {void}
   */
  public copyEnrolmentLink(): void {
    const textArea = document.createElement('textarea');
    textArea.value = this.getEnrolmentLink();
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        this.translateService.get('snackbarMessages.linkCopied').subscribe(value => {
          this.snackBar.open(value, 'OK');
        });
      } else {
        this.translateService.get('snackbarMessages.linkCopyFailed').subscribe(value => {
          this.snackBar.open(value, 'OK');
        });
      }
    } catch (error) {
      this.translateService.get('snackbarMessages.linkCopyFailed').subscribe(value => {
        this.snackBar.open(value, 'OK');
      });
    }

    document.body.removeChild(textArea);
  }

  /**
   *  Get the participant's summary progress for a unit
   * @param participantId
   * @param courseId
   * @returns ParticipantUnitSummaryProgress
   */
  getParticipantAssessmentSummary(participantId: string, courseId: string): ParticipantUnitSummaryProgress {
    return this.selectedEvent.getCourseParticipant(participantId, courseId).assessmentSummary;
  }

  /**
   * Check if the user is assigned as an assessor or trainer to any of the sessions
   * @param userId - the user id to check for
   * @param sessions - the sessions to check
   * @param role - the role to check for
   * @returns {boolean}
   */
  public checkUserAssignedToSessionAsAssessorTrainer(userId: string, sessions: EventSessionModel[], role: 'assessor' | 'trainer'): boolean {
    return sessions.some(session => {
      const user = role === 'assessor' ? session.assessors : session.trainers;
      return user.some(u => u.id === userId);
    });
  }

  public isCompletedEvent(selectedEvent: EventModel): boolean {
    return selectedEvent && selectedEvent.status === EventStatus.COMPLETED;
  }

  public isInReviewEvent(selectedEvent: EventModel): boolean {
    return selectedEvent && selectedEvent.status === EventStatus.IN_REVIEW;
  }

  /**
   * Check if the event requires compliance acknowledgement for trainers or assessors
   * @param type - 'trainer' or 'assessor'
   * @returns {boolean}
   */
  public eventHasComplianceAcknowledgement(type: 'trainer' | 'assessor'): boolean {
    const eventComplianceAcknowledgement = this.selectedEvent.complianceAcknowledgement;

    if (type === 'trainer') {
      return eventComplianceAcknowledgement.trainerCompliance.requireCompliance;

    } else if (type === 'assessor') {
      return eventComplianceAcknowledgement.assessorCompliance.requireCompliance;
    }
  }

  public hasNotCheckedComplianceAcknowledgement(selectedEvent: EventModel, canAssessEventWithCompliance: boolean): boolean {
    const eventRequiresAssessorCompliance = this.eventHasComplianceAcknowledgement('assessor');
    const eventRequiresTrainerCompliance = this.eventHasComplianceAcknowledgement('trainer');
    const userAssignedAssessor = this.checkUserAssignedToSessionAsAssessorTrainer(this.jwtService.getUserId(), selectedEvent.sessions, 'assessor');
    const userAssignedTrainer = this.checkUserAssignedToSessionAsAssessorTrainer(this.jwtService.getUserId(), selectedEvent.sessions, 'trainer');

    // Check if the event requires compliance acknowledgement or if the user is assigned to the event
    if ((!eventRequiresAssessorCompliance && !eventRequiresTrainerCompliance) || (!userAssignedAssessor && !userAssignedTrainer)) {
      return false;
    }


    if ((eventRequiresTrainerCompliance && !userAssignedTrainer) && (!eventRequiresAssessorCompliance && userAssignedAssessor)) {
      return false;
    }


    if ((eventRequiresAssessorCompliance && !userAssignedAssessor) && (!eventRequiresTrainerCompliance && userAssignedTrainer)) {
      return false;
    }


    return !canAssessEventWithCompliance;
  }

  /**
   * Checks if the participant has completed enrolment questions
   * @param participant
   * @returns {boolean}
   */
  public hasParticipantCompletedEnrolmentQuestions(participant: ParticipantModel): boolean {
    return !!(participant.enrolment?.enrolmentQuestions.length);
  }
}
