import { NgModule } from '@angular/core';
import { ThemedDirective } from './themed/themed.directive';
import { TabContainerDirective } from './tab-container/tab-container.directive';
import { FocusScrollDirective } from './focus-scroll/focus-scroll.directive';
import { AnalyticsTrackDirective } from './analytics-track/analytics-track.directive';

@NgModule({
  declarations: [
    AnalyticsTrackDirective,
    FocusScrollDirective,
    TabContainerDirective,
    ThemedDirective,
  ],
  exports: [
    AnalyticsTrackDirective,
    FocusScrollDirective,
    TabContainerDirective,
    ThemedDirective,
  ]
})
export class DirectivesModule {
}
