import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserModel } from '../models/user.model';

@Injectable({providedIn: 'root'})
export class UsersService implements OnDestroy {

  private me$: Observable<UserModel> | null = null;

  constructor(private http: HttpClient) {
  }

  public getMe(): Observable<UserModel> {
    // Since getting the current user is likely to be a common operation, we should cache the result

    if (this.me$ === null) {
      // If the user is not cached, fetch and cache
      this.me$ = this.http.get(environment.api.getMe)
        .pipe(
          map(toUserModel),
          shareReplay(1)
        );
    }

    return this.me$;
  }

  public invalidateCache() {
    this.me$ = null;
  }

  ngOnDestroy() {
    this.invalidateCache();
  }
}

function toUserModel(response: any) {
  const responseType = response?.data?.type;
  if (responseType !== 'user') {
    throw new Error(`Invalid response type: ${responseType}`);
  }

  const {id, attributes} = response.data;
  const user: UserModel = {
    ...attributes,
    dob: new Date(attributes.dob),
    dateCreated: new Date(attributes.dateCreated),
    dateUpdated: new Date(attributes.dateUpdated),
    archiveDate: attributes.archiveDate ? new Date(attributes.archiveDate) : null,
    id: id,
  };

  return user;
}
